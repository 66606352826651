<template>
  <v-dialog :value="value" max-width="500" @input="$emit('input', $event)">
    <v-card>
      <v-card-title>
        Delete item #{{ item.id }}
      </v-card-title>

      <v-card-text>
        Are you sure you want to delete this item?
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text @click="onCancel">
          Cancel
        </v-btn>
        <v-btn text :loading="isLoading" @click="onDelete">
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    item: {
      required: true,
      type: Object,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const onCancel = () => {
      emit('cancel')
    }

    const onDelete = () => {
      emit('delete', props.item)
    }

    return {
      onCancel,
      onDelete,
    }
  },
}
</script>

export default function useUniqueKey(prefix = 'UKEY') {
  const uniqueKey = () => {
    const randomness = Math.random().toString(36).substr(2)
    const dateString = Date.now().toString(36)

    return (`${prefix}-${randomness}${dateString}`).toUpperCase()
  }

  return {
    uniqueKey,
  }
}
